.modal-content {
  border-radius: $border-radius * 3;
  border: none;
  box-shadow: 0 0 10px rgba(0,0,0,.2);
}

.modal-header {
  padding: $modal-inner-padding $modal-inner-padding $modal-inner-padding / 4;
  border-bottom: none;

  .modal-title {
    font-weight: bold;
    font-size: 1.2rem;
  }
}

.modal-body {
  padding: $modal-inner-padding / 4 $modal-inner-padding;
}

.modal-footer {
  padding: $modal-inner-padding / 4 $modal-inner-padding $modal-inner-padding;
  border-top: none;
}