:root {
  --nav-link-color: rgba(0, 0, 0, 0.5);
  --focus-nav-link-color: rgba(0, 0, 0, 0.7);
  --active-nav-link-color: rgba(0, 0, 0, 0.9);
}

.SuperAdminNav {
  margin-left: 0 !important;
  max-height: 20px;
  padding-left: 48px !important;
  width: 100vw;
}

.Navbar-NavLink-Style,
.Navbar-NavLink-Style-Active {
  padding: 8px;
  position: relative;
}

.Navbar-NavLink-Style {
  color: var(--nav-link-color);
}

.Navbar-NavLink-Style:hover,
.Navbar-NavLink-Style:focus,
.Navbar-NavLink-Style-Active:hover,
.Navbar-NavLink-Style-Active:focus {
  color: var(--focus-nav-link-color);
  text-decoration: none;
}

.Navbar-NavLink-Style-Active {
  color: var(--active-nav-link-color);
  font-weight: 600;
}

.Navbar-NavLink-Style:focus::after,
.Navbar-NavLink-Style:hover::after,
.Navbar-NavLink-Style-Active::after {
  bottom: 0;
  content: '';
  height: 1px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 90%;
}

.Navbar-NavLink-Style:focus::after,
.Navbar-NavLink-Style:hover::after {
  background: var(--nav-link-color);
}

.Navbar-NavLink-Style-Active::after {
  background: var(--active-nav-link-color);
}

@media only screen and (max-width: 991px) {

  .Navbar-NavLink-Style:focus::after,
  .Navbar-NavLink-Style:hover::after,
  .Navbar-NavLink-Style-Active::after {
    width: 10%;
    left: 6%;
  }
}