$theme-colors: (
  "primary": #418BE5,
  "text-primary": #418BE5,
  "btn-primary": #418BE5,
  "bg-primary": #418BE5,
  "btn-outline-primary": #418BE5
);
$text-primary: #418BE5;
$button-primary: #418BE5;
$btn-primary: #418BE5;
$btn-link: #418BE5;
$btn-outline-primary: #418BE5;
$bg-primary: #418BE5;

@import '~bootstrap/scss/bootstrap.scss';
@import "scss/variables.override";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "scss/forms.override";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/input-group";
@import "node_modules/bootstrap/scss/custom-forms";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/toasts";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/spinners";
@import "scss/modal.override";
@import "node_modules/bootstrap/scss/utilities";
@import "scss/form-select";

:root {
  --chart-brush: #537bb8;
  --chart-bar: #396FC1;
  --chart-orange: #FB9E00;
  --chart-green: #A4E910;
  --chart-lighter-blue: #b4cff7;
  --brush-green: rgb(71, 163, 76);

  --border-radius: 6px;
  --border-radius-lg: 12px;

  --small-table-bg-color: #fafcdf4b;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Nunito', sans-serif;
}

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px;
}

input[type=file] {
  width: 100%;
}