.form-label + .form-select {
  margin-left: 5px;
}

.form-select {
  flex: 1;
  [class$="-control"] {
    border-radius: 2rem;
    border-color: #ced4da;
    &:hover {
      border-color: #ced4da;
    }
  }
  [class$="-indicatorSeparator"] {
    display: none;
  }
  .custom-singleValue {
    margin-left: 2px;
    margin-right: 2px;
    width: calc(100% - 8px);
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    box-sizing: border-box;
  }
  > span + [class$="-control"] {
    box-shadow: 0 0 0 0.2rem rgba(81, 151, 236, 0.25);
    border-color: #ced4da;
  }

  &.form-select-inline {
    [class$="-control"] {
      border: none;
      font-weight: bold;
    }
  }
}

.form-select-smaller {
  flex: 1;
  font-size: 5;
  [class$="-control"] {
    border-radius: 2rem;
    border-color: #ced4da;
    &:hover {
      border-color: #ced4da;
    }
  }
  [class$="-indicatorSeparator"] {
    display: none;
  }
  .custom-singleValue {
    margin-left: 2px;
    margin-right: 2px;
    width: calc(100% - 8px);
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    box-sizing: border-box;
  }
  > span + [class$="-control"] {
    box-shadow: 0 0 0 0.2rem rgba(81, 151, 236, 0.25);
    border-color: #ced4da;
  }
}