.path {
  stroke-dasharray: 200;
  stroke-dashoffset: 200;
  animation: dash 4s linear alternate infinite;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}