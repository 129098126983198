@import "scss/variables.override";
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/root";
@import "node_modules/bootstrap/scss/reboot";
@import "node_modules/bootstrap/scss/type";
@import "node_modules/bootstrap/scss/images";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/tables";
@import "node_modules/bootstrap/scss/forms";
@import "scss/forms.override";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/transitions";
@import "node_modules/bootstrap/scss/dropdown";
@import "node_modules/bootstrap/scss/button-group";
@import "node_modules/bootstrap/scss/input-group";
@import "node_modules/bootstrap/scss/custom-forms";
@import "node_modules/bootstrap/scss/nav";
@import "node_modules/bootstrap/scss/navbar";
@import "node_modules/bootstrap/scss/card";
@import "node_modules/bootstrap/scss/alert";
@import "node_modules/bootstrap/scss/close";
@import "node_modules/bootstrap/scss/toasts";
@import "node_modules/bootstrap/scss/modal";
@import "node_modules/bootstrap/scss/spinners";
@import "scss/modal.override";
@import "node_modules/bootstrap/scss/utilities";
@import "scss/form-select";

:root {
  --chart-brush: #537bb8;
  --chart-bar: #396FC1;
  --chart-orange: #FB9E00;
  --chart-green: #A4E910;
  --chart-lighter-blue: #b4cff7;
  --chart-background-blue: #14378B;
  --chart-label-blue: #6Ab0F9;
  --soft-background-blue: #EDF5FF;
  --zephyrx-medium-blue: #418BE5;

  --styled-section-padding: 16px;
  --styled-section-background: rgba(248, 248, 250, 0.60);
  --styled-section-border-radius: 0.25rem;
  --styled-section-box-shadow: rgba(131, 131, 135, 0.2) 0px 2px 3px 0px;


  --brush-green: rgb(71, 163, 76);

  --border-radius: 6px;
  --border-radius-lg: 12px;

  --zephyrx-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

#root {
  height: 100%;
}

#main-container {
  height: 100%;
}

.App .navbar {
  margin-bottom: 1rem;
  box-shadow: 0 0 6px rgba(0, 0, 0, .15);
}

.App .navbar-brand a {
  font-weight: bold;
  color: var(--dark);
}

.App .navbar-brand a:hover {
  color: var(--gray);
}

.Close {
  font-weight: 900;
  position: absolute;
  right: 10px;
  top: 10px;
}

.page-form {
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  padding: 2rem;
  margin-bottom: 3rem;
}

.styled-section {
  background-color: var(--styled-section-background);
  border-radius: var(--styled-section-border-radius);
  box-shadow: var(--styled-section-box-shadow);
  padding: var(--styled-section-padding);
}

.font-weight-600 {
  font-weight: 600 !important;
}